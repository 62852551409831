<template>
  <footer>
    <div class="container is-max-desktop">
      <div class="block has-text-centered links pt-5 mb-0 mx-5">
        <a href="/privacy-policy">Privacy Policy</a>
        <span> | </span>
        <a href="/privacy-policy#ca-privacy-rights">CA Privacy Rights</a>
        <span> | </span>
        <a href="/terms-of-service">Terms of Use</a>
      </div>
      <div class="block">
        <p class="has-text-centered pb-3">© {{ currentYear }} AmericanFirstHealth.com</p>
        <p class="footerText has-text-justified mx-5 pb-5">
          Americanfirsthealth.com is a marketplace connecting people with
          insurance companies and the agents/brokers of insurance companies.
          Americanfirsthealth.com does not provide quotes or sell insurance
          directly to consumers, is not a licensed insurance agent or broker,
          and is not affiliated with or endorsed by the government or the
          federal Medicare program. Accordingly, you should not send us (via
          mail or email) any sensitive information, including personal health
          information or applications. Any such communications will not be
          treated as confidential and will be discarded. You are under no
          obligation to use our website or service to initiate contact, nor
          apply for insurance or any product with any broker or insurance
          company. We receive compensation, in the form of referral fees, from
          the insurance carriers, brokers, agents or other offers that we direct
          you to. This website does not always provide you with an offer with
          the best rates or terms. We encourage you to research all available
          insurance policy options relative to your situation. All trademarks
          and copyrights are the property of their respective owners.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-section",
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
};
</script>