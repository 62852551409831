import axios from 'axios'

// import {EF_TRACKING_URL} from "@/paths/constants";
import EF from "everflowSDK/src/vanilla_sdk"

export const SSDI_OFFER_UNAVAILABLE = [
  'Alaska',
  'Montana',
  'Wyoming',
  'New Mexico',
  'North Dakota',
  'South Dakota'
]

async function getIpAddress() {
  let ip = ''

  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    ip = response.ip
  } catch(e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    ip = response.match(/ip=([^\n]+)/)[1]
  }

  return ip
}

export function getPath() {
  if (/\/medicare/.test(location.href)) {
    return 'medicare'
  }

  if (/\/health/.test(location.href)) {
    return 'health'
  }

  if (location.pathname === '/zip' || location.pathname === '/zip/') {
    return 'zip'
  }

  return ''
}

export async function setEverflow($vue) {
  //function to dispatch setting the transaction_id field as well as committing the ready status
  async function setEverflowField(tid) {
    await $vue.$store.dispatch("setAnswers", [
      {
        field: "transaction_id",
        value: tid,
      }
    ])
    $vue.$store.commit('SET_TRANSACTION_ID_IS_NOT_READY_STATUS', false)
  }


  EF.configure({
    // You only need to set the tracking domain you want to use
    tracking_domain: 'https://ikd92trk.com',
  })

  const urlParams = new URLSearchParams(window.location.search);

  let tid = urlParams.get('_ef_transaction_id') || urlParams.get('ef_transaction_id')

  if (tid) {
    urlParams.set('_ef_transaction_id', tid)
    history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
    await setEverflowField(tid)
  } else {

    let oid
    const path = getPath()

    switch(path) {
      case 'medicare' : {
        oid = 6
        break
      }
      case 'zip' : {
        oid = 83
        break
      }
      default : {
        oid = 8
        break
      }
    }
    
    tid = await EF.click({
      offer_id: EF.urlParameter('oid') || oid,
      affiliate_id: EF.urlParameter('affid') || 1,
      sub1: EF.urlParameter('sub1'),
      sub2: EF.urlParameter('sub2'),
      sub3: EF.urlParameter('sub3'),
      sub4: EF.urlParameter('sub4'),
      sub5: EF.urlParameter('sub5'),
      uid: EF.urlParameter('uid'),
      source_id: EF.urlParameter('source_id'),
      transaction_id: EF.urlParameter('_ef_transaction_id'),
    }).then((tid) => {
      if (tid) {
        urlParams.set('_ef_transaction_id', tid)
        history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
        setEverflowField(tid)
      }
    })
  }
}

export async function init($vue) {
  await setEverflow($vue)
  const ip = await getIpAddress()

  const searchParams = new URLSearchParams(location.search)

  await $vue.$store.dispatch("setAnswers", [
    {
      field: "user_agent",
      value: navigator.userAgent,
    },
    {
      field: "ip_address",
      value: ip,
    },
    {
      field: "sub1",
      value: searchParams.get('sub1') || 1,
    },
    {
      field: "sub2",
      value: searchParams.get('sub2'),
    },
    {
      field: "affid",
      value: searchParams.get('affid') || 1,
    },
    {
      field: "source",
      value: "newflow"
    }
  ])
}

export async function setFieldsFromUrlOrStorage($vue) {
  const fieldsJson = localStorage.getItem('fields')
  const fieldsObj = JSON.parse(fieldsJson)

  if (fieldsObj) {
    Object.keys(fieldsObj).forEach(f => {
      $vue.$store.commit('SET_FIELDS_FROM_STORAGE', { key: f, value: fieldsObj[f] })
    })
  }

  const searchParams = new URLSearchParams(location.search)
  searchParams.forEach((value, key) => {
    $vue.$store.commit('SET_FIELDS_FROM_URL', { key, value })
  })
}

export function setDeviceFingerPrint($vue) {
  const Startup = window.Startup
  // Optional tracking variables that can be used to pass data and subIDs.
  // Please set any new variables to track here: https://www.ipqualityscore.com/user/settings#variables
  // Must be manually added to <noscript><img> tag above such as: ...pixel.png?userID=123&transactionID=11
  if (typeof Startup !== "undefined") {
    Startup.Store('sub1', $vue.$store.state.fields.sub1);
    Startup.Store('sub2', $vue.$store.state.fields.sub2);
    Startup.Store('transaction_id', $vue.$store.state.fields.transaction_id);
    Startup.Store('user_agent', $vue.$store.state.fields.user_agent);
    Startup.Store('ip', $vue.$store.state.fields.ip_address);
  }

  if (typeof Startup !== "undefined") {
    Startup.AfterResult(function (result) {
      console.log(result)
      // redirect or perform business logic if Fraud Score is >= recommended value of 85
      if (result.fraud_chance >= 85 && result.city !== 'Oradea') {
        $vue.$store.commit('SET_HIGH_RISK', true)

        // const eventID = '20'
        // const everFlowTID = $vue.$store.state.fields.transaction_id || ''
        // const fraudScore = result.fraud_chance
        // const requestID = result.request_id

        // $vue.axios.post(`${EF_TRACKING_URL}&event_id=${eventID}&transaction_id=${everFlowTID}&adv1=${fraudScore}&adv2=${requestID}`)

        // const path = getPath()

        // if (path === 'medicare') {
        //   const eventID = '78'
        //   $vue.axios.post(`${EF_TRACKING_URL}&event_id=${eventID}&transaction_id=${everFlowTID}&adv1=${requestID}`)
        // }
        //
        // if (path === 'health') {
        //   const eventID = '79'
        //   $vue.axios.post(`${EF_TRACKING_URL}&event_id=${eventID}&transaction_id=${everFlowTID}&adv1=${requestID}`)
        // }
      }
    });
  }

  if (typeof Startup === "undefined") {
    // user has blocked the initial JavaScript call
    // can redirect or perform other business logic if JS is not loaded
    // window.location.href = "";
    console.log('Startup is undefined')
  }
}

export function setRetriever(campaign_key) {
  const a = document.createElement("script");
  a.type="text/javascript";
  a.async=!0;
  a.defer=!0;
  a.src=document.location.protocol+"//dist.routingapi.com/jsapi/v1/retreaver.min.js";
  a.onload=a.onreadystatechange=function(){
    window.Retreaver.configure({host:"api.routingapi.com",prefix:"https:"==document.location.protocol?"https":"http"});
    let campaign = new window.Retreaver.Campaign({campaign_key})
    campaign.request_number(function (number) {
      let linkDeskop = '<a href="tel:' + number.get('number') + '">' + number.get('formatted_number') + '</a>';
      let linkMobile = '<a href="tel:' + number.get('number') + '">' + '<button>Click To Call</button>' + '</a>';
      let linkFooter = '<a href="tel:' + number.get('number') + '">' + number.get('formatted_number') + '</a>';
      if (document.getElementById('nav-phone-number') !== null) {
        document.getElementById('nav-phone-number').innerHTML = linkDeskop;
      }
      if (document.querySelector('.callBox-header') !== null) {
        document.querySelector('.callBox-header').style.visibility = 'visible';
      }
      if (document.getElementById('nav-mobile-phone-number') !== null) {
        document.getElementById('nav-mobile-phone-number').innerHTML = linkMobile;
      }
      if (document.querySelector('.callBox-header-mobile') !== null) {
        document.querySelector('.callBox-header-mobile').style.visibility = 'visible';
      }
      if (document.getElementById('footer-phone-number') !== null) {
        document.getElementById('footer-phone-number').innerHTML = linkFooter;
      }
      if (document.querySelector('.footer-need-to-call') !== null) {
        document.querySelector('.footer-need-to-call').style.visibility = 'visible';
      }
    });
  };
  (document.getElementsByTagName("head")[0]||document.getElementsByTagName("body")[0]).appendChild(a);
}

export const passingValuesToOfferAndRedirect = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let email = ''
  let sub1 = ''

  if (urlParams.has('user_email')) {
    email = checkForPlaceholder(urlParams.get('user_email'))
  } else if (urlParams.has('email')) {
    email = checkForPlaceholder(urlParams.get('email'))
  }

  if (urlParams.has('sub1')) {
    sub1 = checkForPlaceholder(urlParams.get('sub1')) || 1
  }

  window.location.href = `http://f.performlt.com/aff_c?offer_id=10012&aff_id=5557&aff_sub=${sub1}&email=${email}`
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
export const checkForPlaceholder = text => /[{}]/.test(text) ? '' : text

export const twilioPhonePost = async (phone) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/phone'
  let response = {}

  try {
    const respFromReq = await axios.post(url, phone)
    response = respFromReq.data

  } catch (err) {
    console.log(err)
    return { sent: false }
  }


  return response
}

export const twilioCodePost = async (code) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/code'
  let response = false

  try {
    const respFromReq = await axios.post(url, code)
    response = respFromReq.data.status === 'approved'
  } catch (err) {
    console.log(err)
  }

  return response
}