<script>
export default {
  name : "Services"
}
</script>

<template>
  <div class="services-container">
    <div class="services-wrapper">
      <div class="text-container">
        <h3>Let's get insurance <span>right.</span></h3>
        <p>We're committed to helping you find the best plan for your needs</p>
      </div>
      <div class="services-grid">
        <div class="services-grid-item">
          <div class="services-grid-icon">
            <svg class="purple-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-icon-name="business_scales_of_justice"> <path d="M37.22,43.16v-1h-25v1a12.5,12.5,0,0,0,25,0Zm-23.05,1H35.27a10.6,10.6,0,0,1-21.11,0Z"></path>
              <path d="M62.79,43.16a12.5,12.5,0,1,0,25,0v-1h-25Zm23.05,1a10.6,10.6,0,0,1-21.11,0Z"></path>
              <path d="M68,37.11a1,1,0,0,0,1.32-.24l6-8.63,6,8.63a1,1,0,0,0,1.56-1.09L76.06,26l0,0a.93.93,0,0,0-.12-.12.89.89,0,0,0-.08-.09h0l0,0-.15-.06-.14-.06h-.09l-20.89-2A4.58,4.58,0,0,0,51,20V15.73a1,1,0,0,0-1.9,0V20a4.58,4.58,0,0,0-3.53,3.59l-20.89,2h-.09l-.15.06-.15.06,0,0h0a.9.9,0,0,0-.08.09A.94.94,0,0,0,24,26l0,0-6.79,9.74a1,1,0,0,0,1.56,1.09l6-8.62,6,8.62a1,1,0,0,0,1.56-1.09l-5.86-8.41,19.13-1.85A4.58,4.58,0,0,0,49,28.95V64.42H47.46A13.56,13.56,0,0,0,34,77h-6.1a4.1,4.1,0,0,0,0,8.2H72.13a4.1,4.1,0,0,0,0-8.2H66a13.56,13.56,0,0,0-13.5-12.6H51V28.95a4.58,4.58,0,0,0,3.49-3.43l19.13,1.85-5.86,8.41A1,1,0,0,0,68,37.11Zm6.38,44a2.2,2.2,0,0,1-2.2,2.2H27.87a2.2,2.2,0,0,1,0-4.4H72.13A2.2,2.2,0,0,1,74.33,81.12ZM64.15,77H35.85a11.66,11.66,0,0,1,11.61-10.7h5.07A11.66,11.66,0,0,1,64.15,77ZM50,27.15a2.67,2.67,0,1,1,2.67-2.67A2.67,2.67,0,0,1,50,27.15Z"></path>
            </svg>
          </div>
          <div class="services-grid-text">
            <h4>Compare and Shop</h4>
            <p>
              Licensed agents will help you navigate the insurance landscape with confidence.
            </p>
          </div>
        </div>

        <div class="services-grid-item">
          <div class="services-grid-icon">
            <svg
              class="pink-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              data-icon-name="li-message"
            >
              <g>
                <path d="M75.4,23.9H24.6c-2.7,0-4.9,2.2-4.9,4.9v31.3c0,2.7,2.2,4.9,4.9,4.9h34.8l11.2,11.2V65h4.9c2.7,0,4.9-2.2,4.9-4.9V28.8C80.3,26.1,78.1,23.9,75.4,23.9z M78.4,60.1c0,1.6-1.3,2.9-2.9,2.9h-6.8v8.4L60.2,63H24.6c-1.6,0-2.9-1.3-2.9-2.9V28.8c0-1.6,1.3-2.9,2.9-2.9h50.9c1.6,0,2.9,1.3,2.9,2.9V60.1z"></path>
                <rect x="35.3" y="43.4" width="29.3" height="2"></rect>
                <rect x="35.3" y="33.7" width="29.3" height="2"></rect>
                <rect x="35.3" y="53.2" width="19.6" height="2"></rect>
              </g>
            </svg>
          </div>

          <div class="services-grid-text">
            <h4>Real Human Support</h4>
            <p>
              You'll be connected to a real human who can answer any question you have.
            </p>
          </div>
        </div>


        <div class="services-grid-item">
          <div class="services-grid-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" class="purple-svg" data-icon-name="wp-stethoscope_thin"> <path d="M53,50c-1.8,0-3.4,1-4.2,2.4c-4.9-1.7-9.8-2.9-14.8-3.7V36.9c8.3-1.1,13-16.1,13-20.9c0-3.1-1.9-5-5.2-5h-1.1c-0.3-0.6-1-1-1.7-1c-1.1,0-2,0.9-2,2s0.9,2,2,2c0.7,0,1.4-0.4,1.7-1h1.1c3.2,0,3.2,2.3,3.2,3c0,4.7-4.8,19-12,19c-7.2,0-12-14.3-12-19c0-0.7,0-3,3.2-3h1.1c0.3,0.6,1,1,1.7,1c1.1,0,2-0.9,2-2s-0.9-2-2-2c-0.7,0-1.4,0.4-1.7,1h-1.1c-3.2,0-5.2,1.9-5.2,5c0,4.9,4.7,19.8,13,20.9v11.6c-2.7-0.3-5.4-0.5-8-0.5c-10.8,0-12,4.2-12,6c0,3.8,4.1,6,11,6c6.4,0,11-3.8,11-9v-0.2c4.7,0.7,9.4,1.9,14.1,3.5c0,0.2-0.1,0.5-0.1,0.7c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5S55.8,50,53,50z M32,51c0,4.1-3.8,7-9,7c-5.5,0-9-1.5-9-4c0-2.5,3.7-4,10-4c2.6,0,5.3,0.2,8,0.5V51z M53,58c-1.7,0-3-1.3-3-3s1.3-3,3-3c1.7,0,3,1.3,3,3S54.7,58,53,58z"></path></svg>
          </div>

          <div class="services-grid-text">
            <h4>Comprehensive Options</h4>
            <p>
              Medicare, Individual & Family, Small business. No pre-existing conditions excluded.
            </p>
          </div>
        </div>

        <div class="services-grid-item">
          <div class="services-grid-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="pink-svg" data-icon-name="payment_bills_dollar_sign"> <path d="M50,38.11A17.63,17.63,0,1,0,67.63,55.74,17.65,17.65,0,0,0,50,38.11ZM50,71.3A15.56,15.56,0,1,1,65.56,55.74,15.58,15.58,0,0,1,50,71.3Z"></path>
              <path d="M52,55.48l-1.61-.75a12.33,12.33,0,0,1-2.21-1.21,2.75,2.75,0,0,1-.77-1.06A3.8,3.8,0,0,1,47.1,51a2.87,2.87,0,0,1,1-2.23,3.69,3.69,0,0,1,2.57-.87,9.08,9.08,0,0,1,2.14.26,12.26,12.26,0,0,1,1.92.58l0-2.52a7.6,7.6,0,0,0-1.37-.46,8.35,8.35,0,0,0-1.64-.29V43.3H49.06v2.32a5.44,5.44,0,0,0-3.38,1.71,4.61,4.61,0,0,0-1.32,3.26,6.43,6.43,0,0,0,1,3.7,7.5,7.5,0,0,0,3.12,2.44l1.26.59a11.47,11.47,0,0,1,2.08,1.17,2.94,2.94,0,0,1,.82,1.07,3.32,3.32,0,0,1,.29,1.39,2.74,2.74,0,0,1-.8,2,3,3,0,0,1-2.19.79,6.66,6.66,0,0,1-2.08-.37,6.28,6.28,0,0,1-1.67-.76v2.55A6.15,6.15,0,0,0,49.1,66v2.15H51.7l0-2.29a5.11,5.11,0,0,0,2.73-1.67,5,5,0,0,0,1.2-3.31,5.87,5.87,0,0,0-.81-3.16A6.44,6.44,0,0,0,52,55.48Z"></path>
              <path d="M90.37,32.17h-7V25.83H77.95V20.69H22.05v5.14H16.64v6.34h-7V79.31H90.37ZM24.12,22.75H75.88v3.07H24.12ZM18.7,27.89H81.3v4.28H18.7ZM88.31,77.25H11.69v-43H88.31Z"></path>
              <path d="M76.77,55a1.87,1.87,0,1,0,1.87,1.87A1.87,1.87,0,0,0,76.77,55Zm-.19,1.87a.19.19,0,1,1,.38,0C77,57.07,76.58,57.07,76.58,56.86Z"></path>
              <path d="M23.23,55a1.87,1.87,0,1,0,1.87,1.87A1.87,1.87,0,0,0,23.23,55ZM23,56.86a.19.19,0,1,1,.38,0C23.42,57.07,23,57.07,23,56.86Z"></path>
            </svg>
          </div>

          <div class="services-grid-text">
            <h4>Get the Best Price</h4>
            <p>
              The best coverage, on your terms at the lowest price available.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.services-container {
  padding: 20px;
  background-color: #f4f4f4;
  width: 100%;
  .services-wrapper {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;

    .text-container {
      max-width: 768px;
      width: 100%;
      margin: 0 auto;
      h3 {
        font-size: 28px;
        color: rgba(65, 67, 69, 1);
        font-weight: bold;
        letter-spacing: 1.5px;
        text-align: center;

        span {
          color: rgb(226, 40, 102);
        }
      }

      p {
        font-size: 16px;
        margin-top: 15px;
        text-align: center;
        color: rgba(102,102,102,1);
      }
    }

    .services-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
      margin-top: 40px;
      gap: 80px 20px;

      .services-grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .services-grid-icon {
          svg {
            width: 60px;
            height: 60px;
          }

          svg.purple-svg {
            color: rgba(68,66,158,1) !important;
            fill: rgba(68,66,158,1) !important;
          }

          svg.pink-svg {
            color: rgba(226,40,102,1) !important;
            fill: rgba(226,40,102,1) !important;
          }
        }

        .services-grid-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          h4 {
            font-size: 22px;
            color: rgba(90, 90, 90, 1);
            font-family: sans-serif;
            font-weight: bold;
            text-align: left;
          }

          p {
            font-size: 18px;
            color: rgba(102, 102, 102, 1);
            text-align: left;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services-container .services-wrapper .services-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    .services-grid-item {
      max-width: 425px;
      flex-direction: column;
      margin: 0 auto;
      .services-grid-text {
        h4 {
          margin: 0 auto;
        }
      }
    }
  }
}


@media screen and (max-width: 425px) {
  .services-container .services-wrapper .services-grid {
    margin-top: 15px;
    .services-grid-item {
      gap: 0px;

      .services-grid-text {
        h4 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>