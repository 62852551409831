const affordableCareAct = {
  namespaced: true,
  actions: {
    prepareNextStep({ commit }, step) {
      let nextStep = ''

      switch (step) {
        case 'startPath':
          nextStep = 'Zip'
          break

        case 'Zip':
          nextStep = 'LeaveBehindAds'
          break

        case 'LeaveBehindAds':
          nextStep = 'IndividualOrFamilyCoverage'
          break

        case 'IndividualOrFamilyCoverage':
          nextStep = 'HouseholdSize'
          break

        case 'HouseholdSize':
          nextStep = 'HouseholdIncome'
          break

        case 'HouseholdIncome':
          nextStep = 'WhatIsYourGender'
          break

        case 'WhatIsYourGender':
          nextStep = 'WhatIsYourBirthdate'
          break

        case 'WhatIsYourBirthdate':
          nextStep = 'WhatIsYourAddress'
          break

        case 'WhatIsYourAddress':
          nextStep = 'FirstAndLastName'
          break

        case 'FirstAndLastName':
          nextStep = 'WhatIsYourEmailAndPhone'
          break

        case 'WhatIsYourEmailAndPhone':
          nextStep = 'AffordableCareActFinalStep'
          break
      }

      commit('SET_NEXT_STEP', nextStep, { root: true })
      commit('SET_IS_LOADING', true, { root: true })
    }
  }
}

export default affordableCareAct
