const zipSubmit = {
  namespaced: true,
  actions: {
    prepareNextStep({ commit }, step) {
      let nextStep = ''

      switch (step) {
        case 'startPath': {
          nextStep = 'zipSubmit'
          break
        }

        case 'zipSubmit': {
          nextStep = 'leaveBehindAds'
          break
        }

        case 'leaveBehindAds': {
          nextStep = 'zipSubmitThankYou'
          break
        }
      }

      commit('SET_NEXT_STEP', nextStep, { root: true })
      commit('SET_IS_LOADING', true, { root: true })
    }
  }
}

export default zipSubmit
