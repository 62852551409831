<script>
export default {
  name: "HomeFAQ",
  data() {
    return {
      showFirstAnswer: false,
      showSecondAnswer: false,
      showThirdAnswer: false,
      showForthAnswer: false,
      showFifthAnswer: false
    }
  },
  methods: {
    answersToggle(answer) {
      this[answer] = !this[answer]
    }
  }
}
</script>

<template>
  <div class="FAQ-section">
    <div class="FAQ-container">
      <div class="header">Common Insurance Questions</div>
      <div class="content">
        <ul>
          <li>
            <div
              @click="answersToggle('showFirstAnswer')"
              class="question"
            >
              <div class="title-text">
                What is Medicare?
                <div
                  :class="{ 'expand': showFirstAnswer }"
                  class="arrow">
                  <div class="horizontal"></div>
                  <div class="vertical"></div>
                </div>
              </div>
            </div>
            <div
              v-if="showFirstAnswer"
              class="answer"
            >
              Medicare is a government subsidized health insurance program for Americans aged 65 older,
              although some younger people may qualify with disability status. According to annual
              Medicare Trustees reports and research by the government's MedPAC group, Medicare covers
              about half of healthcare expenses of those enrolled. Enrollees almost always cover most
              of the remaining costs by taking additional private insurance and/or by joining a public
              Part C or Part D Medicare health plan.
            </div>
          </li>
          <li>
            <div
              @click="answersToggle('showSecondAnswer')"
              class="question"
            >
              <div class="title-text">
                What is Open Enrollment?
                <div
                  :class="{ 'expand': showSecondAnswer }"
                  class="arrow"
                >
                  <div class="horizontal"></div>
                  <div class="vertical"></div>
                </div>
              </div>
            </div>
            <div
              v-if="showSecondAnswer"
              class="answer"
            >
              Open Enrollment (also known as annual enrollment) is a period of time, usually but
              not always occurring once per year, when employees of companies and organizations
              may make changes to their elected fringe benefit options, such as health insurance.
              The term also applies to the annual period during which individuals may buy individual
              health insurance plans through the online, state-based health insurance exchanges
              established by the Patient Protection and Affordable Care Act.
            </div>
          </li>
          <li>
            <div
              @click="answersToggle('showThirdAnswer')"
              class="question"
            >
              <div class="title-text">
                What is Medicare Part A, B, C, & D?
                <div
                  :class="{ 'expand': showThirdAnswer }"
                  class="arrow"
                >
                  <div class="horizontal"></div>
                  <div class="vertical"></div>
                </div>
              </div>
            </div>
            <div
              v-if="showThirdAnswer"
              class="answer"
            >
              Medicare is comprised of 4 parts. Generally speaking, Part A is Hospital Insurance.
              Part B is Medical Services Insurance. Medicare Part C is a private-insurance alternative
              (also called Medicare Advantage) which allows patients to choose health plans with at
              least the same service coverage as Parts A and B (and most often more), often the benefits
              of Part D, and always an annual out-of-pocket spend limit which A and B lack. A beneficiary
              must enroll in Parts A and B first before signing up for Part C. Medicare Part D covers
              many prescription drugs, though some are covered by Part B. In general, the distinction
              is based on whether or not the drugs are self-administered but even this distinction is
              not total.
            </div>
          </li>
          <li>
            <div
              @click="answersToggle('showForthAnswer')"
              class="question"
            >
              <div class="title-text">
                What is Medigap/Medicare Supplement Insurance?
                <div
                  :class="{ 'expand': showForthAnswer }"
                  class="arrow"
                >
                  <div class="horizontal"></div>
                  <div class="vertical"></div>
                </div>
              </div>
            </div>
            <div
              v-if="showForthAnswer"
              class="answer"
            >
              Medigap, also referred to as Medicare supplement insurance, refers to private health
              insurance plans sold to supplement your Medicare plan. Medigap insurance provides
              coverage for many of the co-pays and some of the co-insurance related to Medicare-covered
              hospital, nursing facility, home health care, ambulance, and doctor charges.
            </div>
          </li>
          <li>
            <div
              @click="answersToggle('showFifthAnswer')"
              class="question">
              <div class="title-text">
                HMO vs PPO?
                <div
                  :class="{ 'expand': showFifthAnswer }"
                  class="arrow"
                >
                  <div class="horizontal"></div>
                  <div class="vertical"></div>
                </div>
              </div>
            </div>
            <div
              v-if="showFifthAnswer"
              class="answer"
            >
              Health Maintenance organization or HMO plans requires a primary care physician (PCP)
              who acts a conduit to direct access to medical services. With an HMO plan and except
              for medical emergency situations, you will need a referral from the PCP in order to
              see other doctors. In a Preferred provider organization plan or PPO plan you are able
              to choose from a group of preferred health providers and specialists but at a usually
              higher monthly premium.
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .FAQ-section {
    padding: 15px 40px;
    .FAQ-container {
      display: flex;
      flex-direction: column;
      max-width: 960px;
      position: relative;
      margin: 0 auto;
      width: 100%;
      .header {
        margin: 8px 0;
        padding: 2px 0;
        font-size: 30px;
        color: rgba(65,67,69,1);
        font-weight: bold;
        text-align: center;
      }
      .content {
        margin-top: 28px;
        ul {
          list-style-type: none;
          margin: 0;
          li {
            .question {
              cursor: pointer;
              .title-text {
                padding: 10px 20px 16px 20px;
                border-bottom: 1px solid #e3e3e3;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;
              }
              .arrow {
                position: relative;
                width: 15px;
                height: 15px;
                transition: transform 0.3s ease-in-out;
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                cursor: pointer;
                &.expand {
                  .horizontal {
                    transform: rotate(90deg);
                    opacity: 0;
                  }
                  .vertical {
                    transform: rotate(90deg);
                  }
                }
                .horizontal {
                  fill: rgba(226,40,102,1);
                  background-color: rgba(226,40,102,1);
                  transition: all 0.5s ease-in-out;
                  transform: rotate(-90deg);
                  position: absolute;
                  width: 100%;
                  height: 10%;
                  left: 50%;
                  margin-left: -50%;
                  top: 50%;
                  margin-top: -5%;
                  opacity: 1;
                }
                .vertical {
                  fill: rgba(226,40,102,1);
                  background-color: rgba(226,40,102,1);
                  transition: all 0.5s ease-in-out;
                  transform: rotate(-90deg);
                  position: absolute;
                  width: 10%;
                  height: 100%;
                  left: 50%;
                  margin-left: -5%;
                  top: 50%;
                  margin-top: -50%;
                }
              }
            }
            .answer {
              padding: 18px 20px;
              border-bottom: solid 1px #e1e1e1;
              color: rgba(102,102,102,1);
              font-weight: 300;
              word-wrap: break-word;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .FAQ-section {
      padding: 15px 20px;
    }
  }
</style>