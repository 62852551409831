<script>
export default {
  name : "Articles"
}
</script>

<template>
<div class="articles-container">
  <div class="articles-wrapper">
    <div class="row">
      <h3 class="title">Make empowered <span>decisions.</span></h3>
    </div>

    <div class="row">
      <div class="articles">
        <div class="article-container">
          <div class="inner-article">
            <a href="/" class="blog-img-link">
              <div class="blog-img medicare-coronavirus"></div>
            </a>

            <div class="article-text">
              <h3 class="article-title">
                <a href="/">
                  Does My Medicare Cover Coronavirus?
                </a>
              </h3>
              <p>
                The Brief How is coronavirus spread? Coronavirus or COVID-19 can spread mainly from person to person,
                which includes close contact with people as well as through respiratory droplets from an infected person when they cough or sneeze.
                It is important to stay home and away from possibly infections people
                What does my Medicare cover if I get coronavirus?
                If you have Medicare, you are at a higher risk of getting seriously ill from COVID-19. The Centers for Disease Control and Prevention (CDC) found that older adults and people with serious chronic
                medical conditions are at higher risk. Furthermore, if you have Medicare, COVID-19 testing and treatment may be covered.
                Please call one of our agents to find out if and how your COVID-19 tests can be covered.
                Medicare Part B (medical insurance) covers a test to see if you have the coronavirus. Your doctor or other health care provider must order the test.
                Although your medical insurance may cover your COVID-19 test, coronavirus testing may or may not be available in your area.
              </p>
            </div>
          </div>
        </div>

        <div class="article-container">
          <div class="inner-article">
            <a href="/" class="blog-img-link">
              <div class="blog-img public-versus-private"></div>
            </a>

            <div class="article-text">
              <h3 class="article-title">
                <a href="/">
                  Original Medicare (Public) or Medicare Advantage (Private): What is The Difference
                </a>
              </h3>

              <p>
                The Brief You face two big options when it comes to Medicare - Public or
                Private Medicare Annual Open Enrolment only happens once a year. If you are
                65+ you are eligible for Medicare! You must now make the choice between public
                and private health insurance. And since you have lived with private insurance
                all your life you may be feeling uncertain which is best. Well good news, you
                can have both.
              </p>
            </div>
          </div>
        </div>

        <div class="article-container">
          <div class="inner-article">
            <a href="/" class="blog-img-link">
              <div class="blog-img seniors-dental-plan"></div>
            </a>

            <div class="article-text">
              <h3 class="article-title">
                <a href="/">
                  Why Seniors Need a Dental Plan
                </a>
              </h3>

              <p>
                The Brief Dry mouth, cavities, periodontitis, and serious gum infections.
                These are some of the dental conditions associated with aging and why seniors
                need a dental plan. The American Dental Association records increasing age as
                a risk factor of certain dental conditions. Poor management of oral health due
                to physical and/or cognitive impairments can also worsen dental problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.articles-container {
  width: 100%;
  padding: 50px 20px;
  .articles-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .title {
        font-size: 30px;
        color: rgba(65, 67, 69, 1);
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
        span {
          color: rgb(68, 66, 158);
        }
      }

      .articles {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        max-width: 1000px;
        grid-gap: 20px;

        .article-container {
          text-align: left;
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
          .inner-article {
            display: flex;
            flex-direction: column;
            min-height: 400px;
            width: 100%;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: rgba(0,0,0,.25) 0 3px 11px 0!important;
            -moz-box-shadow: rgba(0,0,0,.25) 0 3px 11px 0!important;
            -webkit-box-shadow: rgba(0,0,0,.25) 0 3px 11px 0!important;

            .blog-img-link {
              text-decoration: none;
              height: 190px;
              width: 100%;
              
              .blog-img {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }

              .blog-img.medicare-coronavirus {
                background-image: url(../../assets/img/home/pexels-anna-shvets-3902732-fe639ba6-1920w.png);
              }

              .blog-img.public-versus-private {
                background-image: url(../../assets/img/home/pexels-gustavo-fring-4173248-1920w.jpeg);
              }

              .blog-img.seniors-dental-plan {
                background-image: url(../../assets/img/home/pexels-andrea-piacquadio-3881301-1920w.jpeg);
              }
            }

            .article-text {
              padding: 15px;
              flex: 1 1 auto;

              .article-title {
                font-size: 18px;
                font-weight: bold;

                a {
                  text-decoration: none;
                  color: #404345;
                  a:visited {
                    color: inherit;
                  }
                }
              }

              p {
                margin-top: 10px;
                max-height: 4rem;
                font-size: 15px;
                color: #747474;
                overflow: hidden;
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .articles-container .articles-wrapper .row .articles {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 520px) {
  .articles-container .articles-wrapper .row .articles {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>