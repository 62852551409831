<script>
import {mapState} from "vuex";
import EF from 'everflowSDK/src/vanilla_sdk'

export default {
  props: {
    prepare: {
      type: Boolean,
      default: false,
    },
    customProps: {
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      loading: null,
      tapStoneAds: document.getElementById('tap-stone-ads').innerHTML
    };
  },

  computed: {
    ...mapState([
      'fields',
      'fieldsFromUrl',
    ])
  },

  async mounted() {
    await this.getEverFlow()

    if (this.prepare) {
      if (this.fieldsFromUrl['slb'] === '1') {
        this.$emit('next-step');
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete('slb')
        window.history.replaceState({}, null, location.pathname + '?' + searchParams.toString());
        return
      }

      this.$emit('show-this-step')
    }
  },

  methods: {
    async getEverFlow() {
      const urlParams = new URLSearchParams(window.location.search)
      const tid = urlParams.get('ef_tid') || urlParams.get('_ef_transaction_id') || urlParams.get('ef_transaction_id')

      //no need to create an everflow click if we already have transaction_id in url from a redirection
      if (tid) {
        return { ef_transaction_id: tid }
      }

      return new Promise(resolve => {
        const oid = 6 //medicare path
        const interval = setInterval(() => {
          const urlParams = new URLSearchParams(window.location.search)

          EF.configure({
            tracking_domain: 'https://ikd92trk.com'
          })

          EF.click({
            offer_id: oid,
            affiliate_id: 1,
            sub1: EF.urlParameter('sub1'),
            sub2: EF.urlParameter('sub2'),
            sub3: EF.urlParameter('sub3'),
            sub4: EF.urlParameter('sub4'),
            sub5: EF.urlParameter('sub5'),
            uid: EF.urlParameter('uid'),
            source_id: EF.urlParameter('source_id'),
            transaction_id: EF.urlParameter('_ef_transaction_id'),
          })
            .then(tid => {
              if (tid) {
                urlParams.set('_ef_transaction_id', tid)
                history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
                resolve({ef_transaction_id : tid})
              }
            })
          clearInterval(interval)
        }, 5)
      })
    }
  },
};
</script>

<template>
  <div v-if="!prepare">
    <div class="container is-max-widescreen px-5 mt-6">
      <div class="box has-text-centered item-box">
        <h2 class="question mb-3">Your Quotes Are Ready!
          <span class="is-block subtitle-question">Click on a few options to compare rates:</span>
        </h2>
        <div v-html="tapStoneAds"></div>
      </div>
    </div>
  </div>
</template>
