import {SSDI_OFFER_UNAVAILABLE} from "@/paths/helpers";
import Vue from 'vue'
import Vuex from 'vuex'
import medicare from "@/store/medicare";
import health from "@/store/health";
import health_ex from "@/store/health-ex";
import zipSubmit from '@/store/zipSubmit'
import affordableCareAct from '@/store/affordable-care-act'
import twilioSmsVerify from '@/store/twilio-sms-verify'

Vue.use(Vuex)

// Create a new store instance.
const store = new Vuex.Store({
  modules: {
    medicare,
    health,
    zipSubmit,
    health_ex,
    affordableCareAct,
    twilioSmsVerify
  },
  state: {
    steps: {},
    fields: {},
    ageOfUser: 0,
    progress: 5,
    currentStep: null,
    nextStep: null,
    fieldsFromUrl: {},
    fieldsFromStorage: {},
    isLoading: false,
    highRisk: false,
    transactionIdIsNotReady: true,
    phoneError: false,
  },
  getters: {
    isEligibleForSSDIOffer(state) {
      let date = new Date();
      const estDate = new Date(
        date.toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );
      const hourEST = estDate.getHours();
      const day = estDate.getDay();

      return (
        (!SSDI_OFFER_UNAVAILABLE.includes(state.fields["state"])) &&
        (state.ageOfUser >= 53 && state.ageOfUser <= 64) &&
        (hourEST >= 9 && hourEST < 18 && day >= 1 && day <= 5)
      );
    }
  },
  mutations: {
    SET_STEPS(state, steps) {
      state.steps = steps
    },

    SET_CURRENT_STEP( state, step ) {
      state.currentStep = step
    },

    SET_NEXT_STEP(state, step) {
      state.nextStep = step
    },

    SET_FIELDS_FROM_URL(state, { key, value }) {
      state.fieldsFromUrl[key] = value
    },

    SET_FIELDS_FROM_STORAGE(state, { key, value }) {
      state.fieldsFromStorage[key] = value
    },

    SET_STEP_COMPLETED(state, step) {
      state.steps[step].completed = true
    },

    SET_FIELD(state, { field, value }) {
      state.fields[field] = value
    },

    SET_IS_LOADING(state, status) {
      state.isLoading = status
    },

    SET_TRANSACTION_ID_IS_NOT_READY_STATUS(state, status) {
      state.transactionIdIsNotReady = status
    },

    SET_PROGRESS(state, progress) {
      state.progress = progress
    },

    SET_HIGH_RISK(state, status) {
      state.highRisk = status
    },

    SET_AGE_OF_USER(state, {optionalYear}) {
      if (!optionalYear) {
        const birthYear = state.fields['dob_year']
        const currentYear = new Date().getFullYear()
        state.ageOfUser = currentYear - birthYear
        return
      }
      const birthYear = optionalYear
      const currentYear = new Date().getFullYear()
      state.ageOfUser = currentYear - birthYear
    },

    SET_PHONE_ERROR(state, value) {
      state.phoneError = value
    },
  },
  actions: {
    showThisStep({ state, commit }) {
      commit('SET_CURRENT_STEP', state.nextStep)
      if (state.steps[state.nextStep].progress) {
        commit('SET_PROGRESS', state.steps[state.nextStep].progress)
      }
      commit('SET_NEXT_STEP', null)
      commit('SET_IS_LOADING', false)
    },

    setAnswers({ commit }, answers) {
      answers.forEach(answer => {
        commit('SET_FIELD', {
          field: answer.field,
          value: answer.value,
        })

        const fieldsJson = localStorage.getItem('fields')
        const fields = JSON.parse(fieldsJson)
        localStorage.setItem('fields', JSON.stringify({...fields, [answer.field]: answer.value }))
      })
    }
  }
})

export default store
