<script>
export default {
  name : "WhatMattersMost"
}
</script>

<template>
  <div class="what-matters-most-container">
    <div class="what-matters-most-wrapper">
      <div class="col first-col">
        <div class="text-container">
          <h3>
            Helping you cover what
            <br/>
            <span>matters most.</span>
          </h3>
          <hr class="divider"/>
          <p>
            Finding the right coverage can be a daunting task. Navigate the insurance landscape with a veteran representative.
          </p>
        </div>
      </div>

      <div class="col second-col">
        <div class="youtube-container">
          <iframe src="https://www.youtube.com/embed/OPLpB9mEkKk" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.what-matters-most-container {
  margin: 0 auto;
  width: 100%;
  padding: 50px 20px;
  max-width: 1200px;
  .what-matters-most-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;

    .col.first-col {
      max-width: 500px;
    }

    .col.second-col {
      max-width: 500px;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 300px;

      .text-container {
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 28px;
          color: rgba(65, 67, 69, 1);
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 42px;
          text-align: right;
          span {
            color: rgb(226, 40, 102);
          }
        }

        p {
          font-size: 16px;
          text-align: center;
          color: rgba(102, 102, 102, 1);
        }

        .divider {
          background-color: rgb(218,218,218) !important;
          min-height: 3px;
          width: 25%;
          margin-left: auto;
        }
      }

      .youtube-container {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .what-matters-most-container {
    padding: 20px;

    .what-matters-most-wrapper {
      .col.first-col {
        height: unset;
      }

      .col {
        .text-container {
          gap: 20px;

          h3 {
            text-align: right;
          }

          .divider {
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .what-matters-most-container .what-matters-most-wrapper .col .text-container h3 {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (max-width: 625px) {
  .what-matters-most-container .what-matters-most-wrapper {
    flex-direction: column;
  }
}
</style>