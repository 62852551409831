<template>
  <nav class="navbar is-fixed-top">
    <div class="container is-max-desktop is-flex is-justify-content-space-between">
      <img class="logo mt-2 mb-2 ml-5" src="../assets/img/American-First-Health---Logo-Design---final---high-resolution---1-320w.png" alt="American First Health Logo">
      <div
        class="block is-align-items-flex-end is-flex call-section callBox-header"
        style="visibility: hidden;"
      >
        <figure
          class="image is-64x64 mt-1"
          style="margin-bottom: 5px;"
        >
          <img class="is-rounded" src="../assets/img/circle-cropped-120w.jpg">
        </figure>
        <div class="block callBox mb-1 mr-2">
          <p class="topText" style="margin-bottom: -5px;">Have Specific Questions?</p>
          <p class="centerText" >
            <span id="nav-phone-number"></span>
          </p>
          <p class="bottomText" style="margin-top: -5px;">Live Agents Available now</p>
        </div>
      </div>
      <div
        class="block is-align-items-flex-end is-flex mobile-call-section callBox-header-mobile"
        style="visibility: hidden;"
      >
        <span id="nav-mobile-phone-number"></span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "nav-bar",
};
</script>
