<script>
export default {
  name : "CompaniesBanner"
}
</script>

<template>
  <div class="companies-banner">
    <h3>Plans for everyone</h3>
    <div class="companies-wrapper">
      <div class="company" id="blue-cross">
      </div>

      <div class="company" id="humana">

      </div>

      <div class="company" id="cigna">

      </div>

      <div class="company" id="aetna">

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.companies-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  h3 {
    font-size: 28px;
    color: rgba(179,179,179);
    font-family: "Montserrat", sans-serif;
    letter-spacing: 1.8px;
    font-weight: 500;
  }

  .companies-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: 100%;
    max-width: 1000px;
    .company {
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 108px;
    }
    
    .company#blue-cross {
      background-image: url("../../assets/img/home/bluehea-1920w.webp");
    }

    .company#humana {
      background-image: url("../../assets/img/home/Humana-1920w.webp");
    }
    
    .company#cigna {
      background-image: url("../../assets/img/home/Cigna1-679b11f1-1920w.webp");
    }

    .company#aetna {
      background-image: url("../../assets/img/home/Aetnasite-1920w.webp");
    }
  }
}

@media screen and (max-width: 500px) {
  .companies-banner .companies-wrapper {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    .company {
      width: 75%;
    }
  }
}

@media screen and (max-width: 425px) {
  .companies-banner {
    .companies-wrapper {
      max-width: 320px;

      .company {
        width: 100%;
      }
    }
    h3 {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 320px) {
  .companies-banner {
    .companies-wrapper {

    }
  }
}
</style>