<script>
export default {
  name : "HomeRetreaverNumber",

  props: {
    config: {
      type: String,
      default: 'desktop'
    }
  }
}
</script>

<template>
  <div class="retreaver-container">
    <div class="retreaver-desktop-wrapper" v-if="config === 'desktop'">
      <a
        class="retreaver-desktop-link"
        href="tel:(877) 370-0128"
      >
        (877) 370-0128
      </a>

      <div class="agents-container">
        <p>
          TTY 711 | Agents Online Now
        </p>
      </div>
    </div>

    <div class="retreaver-mobile-wrapper" v-if="config === 'mobile'">
      <a
        class="retreaver-mobile-link"
        href="tel:(877) 370-0128"
      >
        <p>Click To Call</p>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.retreaver-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 180px;
  width: 100%;

  .retreaver-desktop-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .retreaver-desktop-link {
      width: 100%;
      background-color: #E22866;
      text-align: center;
      color: #fff;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold !important;

      &:hover {
        background-color: rgba(68,66,158,1) !important;
        background-image: none !important;
        border-color: rgba(68,66,158,1) !important;

        transition: background-color .2s ease, border-color .2s ease;
      }

      transition: background-color .2s ease-out, border-color .2s ease-out;
    }
  }

  .retreaver-mobile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .retreaver-mobile-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 15px;
      border-radius: 50px;
      background-color: rgba(68, 66, 158, 1) !important;
      border-color: rgba(68, 66, 158, 1) !important;
      height: 35px;
      padding: 20px 10px;

      p {
        font-weight: bold !important;
        color: #fff;
        text-align: center;
      }
    }
  }

  .agents-container {
    p {
      color: rgb(68, 66, 158);
      font-weight: bold;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 425px) {
  .retreaver-container {
    max-width: 120px;
  }
}
</style>


<!--Old RetreaverNumber Nav Item-->
<!--<div>-->
<!--<div-->
<!--  class="block is-align-items-flex-end is-flex call-section callBox-header"-->
<!--  style="visibility: hidden;"-->
<!--&gt;-->
<!--  <figure-->
<!--    class="image is-64x64 mt-1"-->
<!--    style="margin-bottom: 5px;"-->
<!--  >-->
<!--    <img class="is-rounded" src="../../assets/img/circle-cropped-120w.jpg">-->
<!--  </figure>-->
<!--  <div class="block callBox mb-1 mr-2">-->
<!--    <p class="topText" style="margin-bottom: -5px;">Have Specific Questions?</p>-->
<!--    <p class="centerText" >-->
<!--      <span id="nav-phone-number"></span>-->
<!--    </p>-->
<!--    <p class="bottomText" style="margin-top: -5px;">Live Agents Available now</p>-->
<!--  </div>-->
<!--</div>-->
<!--<div-->
<!--  class="block is-align-items-flex-end is-flex mobile-call-section callBox-header-mobile"-->
<!--  style="visibility: hidden;"-->
<!--&gt;-->
<!--  <span id="nav-mobile-phone-number"></span>-->
<!--</div>-->
<!--</div>-->