const health = {
  namespaced: true,
  actions: {
    prepareNextStep({ rootState, commit, dispatch, rootGetters }, step) {
      // const urlParams = new URLSearchParams(window.location.search)
      let nextStep = ''

      switch (step) {
        case 'startPath': {
          nextStep = 'zip'
          break
        }

        case 'zip': {
          nextStep = 'leaveBehindAds'
          break
        }

        case 'leaveBehindAds': {
          nextStep = 'whichBestDescribesHealthInsuranceCoverage'
          break
        }

        case 'whichBestDescribesHealthInsuranceCoverage': {
          if (rootState.fields.type_of_health_insurance_coverage === 'medicare') {
            nextStep = 'whatIsYourGender'
          } else {
            nextStep = 'whatKindOfCoverage'
          }
          break
        }

        case 'whatKindOfCoverage': {
          nextStep = 'whatIsYourGender'
          break
        }

        case 'whatIsYourGender': {
          if (rootState.fields.type_of_health_insurance_coverage === 'medicare') {
            nextStep = 'tellUsAboutYourself'
          } else {
            nextStep = 'familySize'
          }
          break
        }

        case 'familySize': {
          nextStep = 'annualHouseholdIncome'
          break
        }

        case 'annualHouseholdIncome': {
          nextStep = 'howWouldYouDescribeYourCredit'
          break
        }

        case 'howWouldYouDescribeYourCredit': {
          nextStep = 'doYouUseAnyTobaccoProducts'
          break
        }

        case 'doYouUseAnyTobaccoProducts': {
          nextStep = 'heightWithValidationNew'
          break
        }

        case 'heightWithValidationNew': {
          nextStep = 'weight'
          break
        }

        case 'weight': {
          nextStep = 'haveYouEverBeenDeniedCoverage'
          break
        }

        case 'haveYouEverBeenDeniedCoverage': {
          nextStep = 'preExistingConditions'
          break
        }

        case 'preExistingConditions': {
          nextStep = 'whichConditions'
          break
        }

        case 'whichConditions': {
          nextStep = 'tellUsAboutYourself'
          break
        }

        case 'tellUsAboutYourself': {
          nextStep = 'whatIsYourBirthdate'
          break
        }

        case 'whatIsYourBirthdate': {
          nextStep = 'whatIsYourAddress'
          break
        }

        case 'whatIsYourAddress': {
          nextStep = 'whatIsYourEmail'
          break
        }

        case 'whatIsYourEmail': {
          if (rootState.fields.type_of_health_insurance_coverage === 'medicare') {
            nextStep = 'phoneNumber'
          } else {
            if (rootGetters.isEligibleForSSDIOffer) {
              nextStep = 'doYouHaveADisability'
            } else {
              const answers = [
                { field: 'disability', value: 'no' },
                { field: 'disability_0_1', value: 0 },
                { field: 'disability_t_f', value: 'false' },
              ]
              dispatch('setAnswers', answers, {root: true})
              nextStep = 'phoneNumber'
            }
          }
          break
        }

        case 'doYouHaveADisability': {
          nextStep = 'phoneNumber'
          break
        }

        case 'phoneNumber': {
          nextStep = 'healthFinalStep'
          // if (urlParams.get('affid') === '11') {
          //   nextStep = 'fluentRedirectPage'
          // } else {
          //   nextStep = 'healthFinalStep'
          // }
          break
        }
      }

      commit('SET_NEXT_STEP', nextStep, { root: true })
      commit('SET_IS_LOADING', true, { root: true })
    }
  }
}

export default health
