<script>
export default {
  name : "RealHelp"
}
</script>

<template>
  <div class="real-help-container">
    <div class="real-help-wrapper">
      <div class="col first-col">
        <div class="photo-container">
        </div>
      </div>

      <div class="col second-col">
        <div class="text-container">
          <h3>Real Humans. <span>Local Help.</span></h3>
          <hr class="divider"/>
          <p>
            When you connect through American First Health, we pair you with a highly trained agent who is licensed in your resident state.
            We consistently rate between 4.8 and 5 in customer satisfaction ratings.
          </p>
          <a class="get-quote" href="tel:(877) 370-0128">
            <span>
              Get a Quote Now
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.real-help-container {
  max-width: 1200px;
  width: 100%;
  padding: 50px 20px;
  margin: 0 auto;
  .real-help-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .col.second-col {
      width: 100%;
      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        h3 {
          font-size: 28px;
          color: rgba(65, 67, 69, 1);
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 42px;
          margin-right: auto;
          span {
            color: rgb(226, 40, 102);
          }
        }

        p {
          font-size: 16px;
          text-align: left;
          color: rgba(102, 102, 102, 1);
        }

        .divider {
          background-color: rgb(218,218,218) !important;
          min-height: 3px;
          width: 25%;
          margin-right: auto;
        }

        .get-quote {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          max-width: 320px;
          width: 100%;
          text-decoration: none;
          cursor: pointer;
          border: 2px solid rgba(65,67,69,1);
          margin-top: 20px;
          color: #45484a;
          font-size: 15px;

          &:hover {
            background-color: rgba(65,67,69,1);
            color: #fff;
            transition: all 0.3s ease-in;
          }

          transition: all 0.3s ease-out;
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .photo-container {
        background-repeat: no-repeat;
        background-image: url(../../assets/img/home/shutterstock_1606121224-2880w.webp);
        background-size: cover;
        width: 100%;
        height: 350px;
        box-shadow: rgb(158 158 158) -3px 3px 11px 0 !important;
        -moz-box-shadow: rgba(158,158,158,1) -3px 3px 11px 0 !important;
        -webkit-box-shadow: rgb(158 158 158) -3px 3px 11px 0 !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .real-help-container .real-help-wrapper {
    gap: 20px;

    .col {
      .photo-container {
        height: 320px;
      }
    }

    .col.second-col {
      width: 65%;

      .text-container {
        h3 {
          font-size: 28px;
          line-height: 30px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .real-help-container {
    padding: 20px;
    .real-help-wrapper {
      flex-direction: column;

      .col.second-col {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .real-help-container .real-help-wrapper .col.second-col .text-container h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 350px) {
  .real-help-container .real-help-wrapper .col.second-col .text-container h3 {
    font-size: 18px;
  }
}
</style>