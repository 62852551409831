<script>
export default {
  name : "GetQuoteSection"
}
</script>

<template>
  <div class="get-quote-section">
    <div class="get-quote-wrapper">
      <div class="col first-col">
        <h3>
          Get a quote from a licensed agent in minutes!
        </h3>
      </div>

      <div class="col second-col">
        <a href="tel:(877) 370-0128" class="click-to-connect">
          <span>Click to Connect</span>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.get-quote-section {
  width: 100%;
  padding: 50px;
  background-image: linear-gradient(450deg,rgba(68,66,158,1) 7%,rgba(226,40,102,1) 100%);
  background-origin: border-box;
  .get-quote-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .col.first-col {
      width: 100%;
      max-width: 550px;
    }

    .col.second-col {
      max-width: 320px;
      width: 100%;
    }
  ;

    .col {
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        font-weight: bold;
        color: #fff;
        font-size: 30px;
        text-align: left;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        height: 50px;
        max-width: 225px;
        width: 100%;
        background-color: #fff;

        span {
          font-size: 16px;
          color: rgba(68, 66, 158, 1);
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .get-quote-section {
    padding: 20px;
  }
}

@media screen and (max-width: 520px) {
  .get-quote-section .get-quote-wrapper {
    flex-direction: column;
    gap: 20px;

    .col h3 {
      text-align: center;
      font-size: 24px;
    }

    .col a span {
      font-size: 14px;
    }
  }
}
</style>