<script>
export default {
  name : "Testimonials"
}
</script>

<template>
  <div class="testimonials-container">
    <div class="testimonials-wrapper">
      <div class="row">
        <h3 class="title">We've helped thousands <span>find their way.</span></h3>
      </div>

      <div class="row">
        <div class="testimonials">
          <div class="col">
            <div class="user-image">
              <img src="../../assets/img/home/user-image-1.webp" alt=""/>
            </div>

            <div class="quotation-mark">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" id="1152100270" class="svg u_1152100270" data-icon-custom="true" data-icon-name="Quote.svg"> <path d="M5.2,42.7c1.3,8.7,8.7,14.9,17.1,15.3c-2.1,7.8-7.2,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l1,6.2c0,0,0.1,0.5,0.9,0.4  c21.4-2.4,35.9-18.7,33.2-38.5C41,25.9,30.3,20.6,20.4,22C10.5,23.6,3.7,32.8,5.2,42.7L5.2,42.7z M73.5,58  c-2,7.8-7.3,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l0.9,6.2c0,0,0.1,0.5,0.9,0.4c21.5-2.4,35.8-18.7,33.3-38.5  C92.1,25.9,81.3,20.6,71.5,22c-9.9,1.6-16.7,10.8-15.1,20.7C57.7,51.5,65,57.7,73.5,58z" id="1722105879"></path></svg>
            </div>

            <div class="testimonial-text">
              <p>
                “I had almost gave up trying to educate myself about medicare. Thanks for the patience and guidance AFH."
              </p>
            </div>

            <div class="user-info">
              <p>
                Evelyn C, Jersey City
              </p>
            </div>
          </div>

          <div class="col">
            <div class="user-image">
              <img src="../../assets/img/home/user-image-2.webp" alt=""/>
            </div>

            <div class="quotation-mark">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" id="1152100270" class="svg u_1152100270" data-icon-custom="true" data-icon-name="Quote.svg"> <path d="M5.2,42.7c1.3,8.7,8.7,14.9,17.1,15.3c-2.1,7.8-7.2,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l1,6.2c0,0,0.1,0.5,0.9,0.4  c21.4-2.4,35.9-18.7,33.2-38.5C41,25.9,30.3,20.6,20.4,22C10.5,23.6,3.7,32.8,5.2,42.7L5.2,42.7z M73.5,58  c-2,7.8-7.3,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l0.9,6.2c0,0,0.1,0.5,0.9,0.4c21.5-2.4,35.8-18.7,33.3-38.5  C92.1,25.9,81.3,20.6,71.5,22c-9.9,1.6-16.7,10.8-15.1,20.7C57.7,51.5,65,57.7,73.5,58z" id="1722105879"></path></svg>
            </div>

            <div class="testimonial-text">
              <p>
                “Our rep made comparing plans a breeze and we found exactly what we needed. True competence is rare and we definitely lucked out."
              </p>
            </div>

            <div class="user-info">
              <p>
                Thomas J, Virginia Beach
              </p>
            </div>
          </div>

          <div class="col">
            <div class="user-image">
              <img src="../../assets/img/home/user-image-3.webp" alt=""/>
            </div>

            <div class="quotation-mark">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" id="1152100270" class="svg u_1152100270" data-icon-custom="true" data-icon-name="Quote.svg"> <path d="M5.2,42.7c1.3,8.7,8.7,14.9,17.1,15.3c-2.1,7.8-7.2,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l1,6.2c0,0,0.1,0.5,0.9,0.4  c21.4-2.4,35.9-18.7,33.2-38.5C41,25.9,30.3,20.6,20.4,22C10.5,23.6,3.7,32.8,5.2,42.7L5.2,42.7z M73.5,58  c-2,7.8-7.3,10.9-13.4,12.7c-0.6,0.2-0.5,0.8-0.5,0.8l0.9,6.2c0,0,0.1,0.5,0.9,0.4c21.5-2.4,35.8-18.7,33.3-38.5  C92.1,25.9,81.3,20.6,71.5,22c-9.9,1.6-16.7,10.8-15.1,20.7C57.7,51.5,65,57.7,73.5,58z" id="1722105879"></path></svg>
            </div>

            <div class="testimonial-text">
              <p>
                "We were instantly connected with a rep, explained our options, and had coverage for our whole family 48 hours later. Thanks again"
              </p>
            </div>

            <div class="user-info">
              <p>
                Patricia D, Scottsdale
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.testimonials-container {
  width: 100%;
  background-color: #f4f4f4;
  padding: 50px 20px;

  .testimonials-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .title {
        font-size: 30px;
        color: rgba(65, 67, 69, 1);
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
        span {
          color: rgb(226, 40, 102);
        }
      }

      .testimonials {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        width: 100%;

        .col {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          max-width: 400px;
          height: 290px;
          gap: 10px;

          .user-image {
            height: auto;
            img {
              border-radius: 50%;
              width: 100%;
              height: 85px;
            }
          }

          .quotation-mark {
            svg {
              color: rgba(169,169,169,1) !important;
              fill: rgba(169,169,169,1) !important;
              height: 25px;
            }
          }

          .testimonial-text p {
            font-size: 16px;
            text-align: center;
            color: #868686;
            max-width: 250px;
            width: 100%;
            font-style: italic;
          }

          .user-info {
            margin-top: auto;
            p {
              text-align: center;
              font-size: 16px;
              color: #222526;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .testimonials-container .testimonials-wrapper .row .testimonials {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .col {
      height: unset;
      .testimonial-text {
        p {
          max-width: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 425px){
  .testimonials-container {
    padding: 20px;
  }
}
</style>