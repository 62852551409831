const medicare = {
  namespaced: true,
  actions: {
    prepareNextStep({rootState, commit, dispatch, rootGetters}, step) {
      let nextStep = ''

      switch(step) {
        case 'startPath': {
          nextStep = 'zip'
          break
        }

        case 'zip': {
          nextStep = 'leaveBehindAds'
          break
        }

        case 'leaveBehindAds': {
          nextStep = 'coverageTypeMedicare'
          break
        }

        case 'coverageTypeMedicare': {
          nextStep = 'coverageTypeTypeOfMedicarePlan'
          break
        }

        case 'coverageTypeTypeOfMedicarePlan': {
          nextStep = 'supplementOrAdvantage'
          break
        }

        case 'supplementOrAdvantage': {
          nextStep = 'whatIsYourGender'
          break
        }

        case 'whatIsYourGender': {
          nextStep = 'tellUsAboutYourself'
          break
        }

        case 'tellUsAboutYourself': {
          nextStep = 'whatIsYourAddress'
          break
        }

        case 'whatIsYourAddress': {
          nextStep = 'whatIsYourBirthdate'
          break
        }

        case 'whatIsYourBirthdate': {
          //if user is under or equal 65, they will be shown the health path
          if (rootState.ageOfUser <= 65) {
            nextStep = 'whatKindOfCoverage'
          } else {
            nextStep = 'whatIsYourEmail'
          }
          break
        }

        case 'whatKindOfCoverage': {
          nextStep = 'familySize'
          break
        }

        case 'familySize': {
          nextStep = 'annualHouseholdIncome'
          break
        }

        case 'annualHouseholdIncome': {
          nextStep = 'howWouldYouDescribeYourCredit'
          break
        }

        case 'howWouldYouDescribeYourCredit': {
          nextStep = 'doYouUseAnyTobaccoProducts'
          break
        }

        case 'doYouUseAnyTobaccoProducts': {
          nextStep = 'heightWithValidationNew'
          break
        }

        case 'heightWithValidationNew': {
          nextStep = 'weight'
          break
        }

        case 'weight': {
          nextStep = 'haveYouEverBeenDeniedCoverage'
          break
        }

        case 'haveYouEverBeenDeniedCoverage': {
          nextStep = 'preExistingConditions'
          break
        }

        case 'preExistingConditions': {
          nextStep = 'whichConditions'
          break
        }

        case 'whichConditions': {
          nextStep = 'whatIsYourEmail'
          break
        }

        case 'whatIsYourEmail': {
          //if user is under or equal 65, they will be shown the health path
          if (rootState.ageOfUser <= 65) {
            //if user is on health path we must check if the do you have a disability component should be shown next
            if (rootGetters.isEligibleForSSDIOffer) {
              nextStep = 'doYouHaveADisability'
            } else {
              const answers = [
                {field: 'disability', value: 'no'},
                {field: 'disability_0_1', value: 0},
                {field: 'disability_t_f', value: 'false'},
              ]
              dispatch('setAnswers', answers, {root: true})
              nextStep = 'phoneNumber'
            }
          } else {
            //if user is on medicare path we want to direct them to phone number
            nextStep = 'phoneNumber'
          }
          break
        }

        case 'doYouHaveADisability': {
          nextStep = 'phoneNumber'
          break
        }

        case 'phoneNumber': {
          //if user is under or equal 65, they will be shown the health path
          if (rootState.ageOfUser <= 65) {
            nextStep = 'healthFinalStep'
          } else {
            nextStep = 'medicareFinalStep'
          }
        }
      }
      commit('SET_NEXT_STEP', nextStep, { root: true })
      commit('SET_IS_LOADING', true, { root: true })
    }
  },
}

export default medicare