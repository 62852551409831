<script>
import { Slide } from 'vue-burger-menu'

import HomeRetreaverNumber from '@/components/Home/nav/HomeRetreaverNumber'

export default {
  name : "HomeNav",
  components: {
    HomeRetreaverNumber,
    Slide
  },
  data() {
    const urlParams = new URLSearchParams(location.search)
    const params = '?' + urlParams.toString()
    const path = location.pathname
    return {
      path,
      medicarePath: process.env.VUE_APP_MEDICARE_PATH + params,
      healthPath: process.env.VUE_APP_HEALTH_PATH + params,
      affordableCareActPath: process.env.VUE_APP_AFFORDABLE_CARE_ACT_PATH + params
    }
  }
}
</script>

<template>
  <nav class="navbar is-fixed-top">
    <div class="desktop-view container is-max-desktop is-flex is-justify-content-space-between is-align-items-center">
      <img class="logo mt-2 mb-2 ml-5" src="../../../assets/img/American-First-Health---Logo-Design---final---high-resolution---1-320w.png" alt="American First Health Logo">
      <div class="navigation-items desktop-navigation-items">
        <ul class="desktop-navbar-menu">
          <li class="navbar-item">
            <a :href="path">
              Home
            </a>
          </li>
          <li class="navbar-item">
            <a :href='medicarePath'>
              Medicare
            </a>
          </li>
          <li class="navbar-item">
            <a :href="healthPath">
              Health
            </a>
          </li>
          <li class="navbar-item">
            <a :href="affordableCareActPath">
              ACA
            </a>
          </li>
        </ul>
      </div>

      <home-retreaver-number :config="'desktop'"/>
    </div>

    <div class="mobile-view is-mobile is-flex is-justify-content-space-between is-align-items-center">
      <Slide>
        <a :href="path" id="home">Home</a>
        <a :href="medicarePath" id="medicare">Medicare</a>
        <a :href="healthPath" id="health">Health</a>
        <a :href="affordableCareActPath">Affordable care act</a>
        <a href="tel:(877) 370-0128" id="burger-call-us">Call Us</a>
        <a href="mailto:info@americanfirsthealth.com" id="burger-email-us">Email Us</a>
      </Slide>

      <img class="logo" src="../../../assets/img/American-First-Health---Logo-Design---final---high-resolution---1-320w.png" alt="American First Health Logo">

      <home-retreaver-number :config="'mobile'"/>
    </div>
  </nav>
</template>

<style lang="scss">
nav {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  .desktop-view {
    .desktop-navigation-items {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .desktop-navbar-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 320px;
        width: 100%;
      }
    }
  }

  .bm-menu {
    background-color: #fff !important;
    .bm-item-list {
      box-shadow: unset !important;
    }
  }

  .mobile-view {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .bm-burger-button {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    height: 20px !important;
    .bm-burger-bars {
      height: 10% !important;
    }
  }

  nav {
    height: unset !important;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    .desktop-view {
      display: none !important;
    }

    .mobile-view {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

@media (min-width: 521px) and (max-width: 800px) {
  nav {
    height: 75px !important;
  }
}

@media screen and (max-width: 768px) {
  nav {
    height: unset !important;
    padding: 10px;
  }
}

@media screen and (max-width: 755px) {
}

@media screen and (max-width: 380px) {
  nav {
    .mobile-view {
      img {
        display: none;
      }

      .retreaver-container {
        max-width: 180px;

        .retreaver-mobile-wrapper {
          .retreaver-mobile-link {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 280px) {
  nav .mobile-view {
    gap: 10px;
  }
}
</style>